import { gql } from '@apollo/client'

export const GET_COMPANIES = gql`
  query Companies {
    companies {
      depots {
        id
        name
      }
      id
      isCompanyCustomer
      country
      organizationNumber
      logo(settings: { height: 220, width: 220 }) {
        id
        url
      }
      name
      projects {
        id
        name
      }
    }
  }
`
