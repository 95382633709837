import { Query } from '@apollo/client/react/components'

import dotProp from 'dot-prop'
import { GET_COMPANIES } from './queries'

export default function withCompanies(
  WrappedComponent,
  { skipLoading, query } = {},
) {
  return (props) => {
    return (
      <Query query={query || GET_COMPANIES}>
        {({ data, loading, client }) => {
          if (!data || (loading && !skipLoading)) return null

          const companies = dotProp.get(data, 'companies', [])

          return <WrappedComponent companies={companies} {...props} />
        }}
      </Query>
    )
  }
}
